import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import RichText from '../RichText'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import AnimateImage from '../_animation/AnimateImage'
import Spacer from '../Spacer'
import PageTransitionLink from '../PageTransitionLink'
import {
  ArticleMobileSpacer,
  ArticleFeatureImage,
  ArticleTitle,
  ArticleIframe,
  ArticleIntro,
  ArticleAnchor,
  ArticleContent,
  ArticleText,
  ArticleTitleWrapper,
  ArticleScrollCTA,
  ArticleIntroImage,
  ArticleNext,
  ArticleNextLabelRight,
  ArticleNextLabelDown,
  ArticleNextImage,
  ArticleNextDate,
  ArticleNextName,
} from './index.style'
import { ScrollWrapper } from '../Scroll/index.style'
import { colors } from '../../styles/vars/colors.style'
import ScrollSection from '../ScrollSection'
import { animation } from '../../styles/vars/animation.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import Video from '../Video'
import Gallery from '../Gallery'
import { scrollTo } from '../../utils/utils'
import SlideMask from '../SlideMask'

const Article = ({
  title,
  name,
  featureImage,
  label,
  soundcloud,
  vimeo,
  intro,
  introImage,
  article,
  art,
  next,
}) => {
  const entryDelay = animation.pageEntryDuration
  const urlPrefix = '/press/'
  const typeLabel = 'Gallery'
  const galleryName = 'Gallery'
  const hasIntro = intro || soundcloud || vimeo

  return (
    <ScrollWrapper color={colors.light} removeNoise={true}>
      {featureImage && (
        <ScrollSection width={1021}>
          <ArticleFeatureImage>
            <AnimateImage>
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={featureImage.gatsbyImageData}
                alt={featureImage.description}
              />
            </AnimateImage>
          </ArticleFeatureImage>
        </ScrollSection>
      )}
      <ScrollSection width={900}>
        <ArticleTitle>
          <ArticleTitleWrapper>
            <AnimateSplitText delay={entryDelay} heading>
              {name}
            </AnimateSplitText>
          </ArticleTitleWrapper>
          <ArticleScrollCTA
            onClick={() =>
              scrollTo(
                intro
                  ? 'articleIntro'
                  : introImage
                  ? 'articleIntroImage'
                  : article
                  ? 'articleContent'
                  : art
                  ? 'articleArt'
                  : ''
              )
            }
          >
            <AnimateSlideIn
              delay={animation.pageEntryDuration}
              direction="left"
            >
              <SlideMask direction="horizontal">
                <IconArrowheadRight responsive />
              </SlideMask>
            </AnimateSlideIn>
          </ArticleScrollCTA>
        </ArticleTitle>
      </ScrollSection>
      {hasIntro && (
        <ScrollSection width={900} id="articleIntro">
          <ArticleIntro>
            {soundcloud && (
              <ArticleIframe>
                <AnimateImage delay={0.3} scale={false}>
                  <iframe
                    width="100%"
                    height="150"
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${soundcloud}&color=%235ba5c5&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=false`}
                    title={`SoundcloudEmbed`}
                  ></iframe>
                </AnimateImage>
              </ArticleIframe>
            )}
            {vimeo && (
              <ArticleIframe>
                <Video vimeoId={vimeo} delay={0.3} scale={false} />
              </ArticleIframe>
            )}
            {intro && (
              <ArticleText>
                <RichText content={intro} delay={0.3} />
              </ArticleText>
            )}
            {introImage && <ArticleMobileSpacer />}
          </ArticleIntro>
        </ScrollSection>
      )}
      {introImage && (
        <ScrollSection width={1021} id="articleIntroImage">
          <ArticleIntroImage>
            <AnimateImage delay={0.3}>
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={introImage.gatsbyImageData}
                alt={introImage.description}
              />
            </AnimateImage>
          </ArticleIntroImage>
        </ScrollSection>
      )}
      {article &&
        React.Children.toArray(
          article.map((text, index) => (
            <ScrollSection
              width={
                article.length === 1 && (!hasIntro || introImage)
                  ? 1050
                  : index === 0 && article.length > 1
                  ? 850
                  : index === article.length - 1
                  ? 760
                  : index % 2 === 0
                  ? 550
                  : 680
              }
            >
              <ArticleContent last={index === article.length - 1}>
                {index === 0 && (
                  <ArticleAnchor
                    id="articleContent"
                    spacer={introImage || (!hasIntro && !introImage)}
                  ></ArticleAnchor>
                )}
                {index === 0 && introImage && <ArticleMobileSpacer />}
                <ArticleText>
                  <RichText content={text.text} delay={0.3} />
                </ArticleText>
              </ArticleContent>
            </ScrollSection>
          ))
        )}
      }
      {art && (
        <Gallery
          art={art}
          title={title}
          name={galleryName}
          typeLabel={typeLabel}
          next={next}
          urlPrefix={urlPrefix}
          id="articleArt"
          fullHeight={true}
        />
      )}
      {next && (
        <ScrollSection width={938}>
          <ArticleNext>
            <ArticleNextLabelRight aria-hidden>
              <AnimateSlideIn delay={0} direction="left">
                Stay in the know
              </AnimateSlideIn>
            </ArticleNextLabelRight>

            <ArticleNextLabelDown aria-hidden>
              <AnimateSlideIn delay={0} direction="right">
                Next Article
              </AnimateSlideIn>
            </ArticleNextLabelDown>

            {next.featureImage && (
              <ArticleNextImage>
                <AnimateImage>
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={next.featureImage.gatsbyImageData}
                    alt={next.featureImage.description}
                  />
                </AnimateImage>
              </ArticleNextImage>
            )}

            {next.featureImage && <Spacer axis="vertical" size={20} />}

            {next.dateOfPublish && (
              <ArticleNextDate aria-hidden>
                <AnimateSplitText delay={0.15}>
                  {next.dateOfPublish}
                </AnimateSplitText>
              </ArticleNextDate>
            )}

            <ArticleNextName aria-hidden>
              <AnimateSplitText delay={0.3}>{next.name}</AnimateSplitText>
            </ArticleNextName>

            <PageTransitionLink to={`${urlPrefix}${next.slug}`} fill>
              Next: {next.name}
            </PageTransitionLink>
          </ArticleNext>
        </ScrollSection>
      )}
    </ScrollWrapper>
  )
}

Article.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  featureImage: PropTypes.object,
  label: PropTypes.string,
  soundcloud: PropTypes.string,
  vimeo: PropTypes.string,
  intro: PropTypes.object,
  introImage: PropTypes.object,
  article: PropTypes.array,
  art: PropTypes.array,
  next: PropTypes.object,
}

export default Article

import styled, { css } from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { flow, horizontalPadding } from '../../styles/utils/functions.style'
import { colors } from '../../styles/vars/colors.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import { SplitTextWrapper } from '../_animation/AnimateSplitText/index.style'
import {
  VideoMain,
} from '../Video/index.style'

export const ArticleMobileSpacer = styled.div`
  ${mq.belowHorizontal} {
    height: 11rem;
  }
`

export const ArticleFeatureImage = styled.div`
  padding-top: 11.1rem;

  ${mq.horizontal} {
    height: 100%;
    padding-left: 13rem;
    padding-top: ${horizontalScale(186)};
  }
`

export const ArticleTitle = styled.div`
  ${mq.belowHorizontal} {
    padding-bottom: 5rem;
    ${horizontalPadding()}
  }

  ${mq.horizontal} {
    align-self: flex-start;
    margin-left: ${horizontalScale(-180)};
    overflow: hidden;
    padding-top: ${horizontalScale(249)};
  }
`

export const ArticleTitleWrapper = styled.div`
  position: relative;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 5.6rem;
  line-height: 0.84;
  transform: translateY(-2rem);
  text-transform: uppercase;
  text-align: left;
  word-break: break-all;
  white-space: normal;
  width: 100%;

  ${mq.mobileL} {
    max-width: none;
  }

  ${mq.tabletP} {
    font-size: 12rem;
  }

  ${mq.horizontal} {
    max-width: 5.2em;
    font-size: ${horizontalScale(180)};
    letter-spacing: -0.01em;
    transform: none;
  }
`

export const ArticleScrollCTA = styled.button`
  display: none;

  ${mq.horizontal} {
    display: block;
    position: absolute;
    right: ${horizontalScale(84)};
    bottom: ${horizontalScale(110)};
    display: block;
    width: ${horizontalScale(50)};
    border: none;
    background-color: transparent;
  }
`

export const ArticleIntro = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;

  ${mq.belowHorizontal} {
    padding-bottom: 4rem;
    ${horizontalPadding()}
  }

  ${mq.horizontal} {
    background-color: ${colors.light2};
    padding-bottom: ${horizontalScale(122)};
    padding-left: ${horizontalScale(130)};
    padding-right: ${horizontalScale(300)};
    padding-top: ${horizontalScale(186)};
  }
`

export const ArticleIframe = styled.div`
  margin-bottom: 4rem;

  ${mq.horizontal} {
    margin-bottom: ${horizontalScale(51)};
  }

  ${VideoMain} {
    padding-bottom: 0;
    padding-left: 0;
    width: 100%;
  }

  iframe {
    background-color: ${colors.light};

    ${mq.horizontal} {
      background-color: ${colors.light2};
    }
  }
`

export const ArticleIntroImage = styled.div`
  ${mq.horizontal} {
    height: 100%;
  }
`

export const ArticleIntroLabel = styled.div`
  position: absolute;
  top: ${horizontalScale(4)};
  left: ${horizontalScale(110)};
  transform-origin: 0% 0%;
  transform: rotate(90deg);
  display: none;
  font-size: ${horizontalScale(12)};
  line-height: 1;
  text-transform: uppercase;

  ${mq.horizontal} {
    display: block;
  }
`

export const ArticleName = styled.h3`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(38)};
  }
`

export const ArticleLabel = styled.h4`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    margin-top: ${horizontalScale(4)};
    font-size: ${horizontalScale(28)};
  }
`

export const ArticleText = styled.div`
  ${flow(1.5)}
  font-size: 2.1rem;

  ${mq.horizontal} {
    ${ArticleName} + &,
    ${ArticleLabel} + & {
      margin-top: ${horizontalScale(32)};
    }
  }

  > p {
    font-weight: ${font.weight.light};
    font-size: 2.1rem;
    line-height: 1.5;
    letter-spacing: -0.02em;

    ${mq.horizontal} {
      font-size: ${horizontalScale(21)};
    }
  }
`

export const ArticleAnchor = styled.div`
  ${mq.horizontal} {
    background-color: ${colors.light2};
    height: 100%;
    min-width: ${props => props.spacer ? horizontalScale(300) : '0'};
  }
`

export const ArticleContent = styled.div`
  background-color: ${colors.light2};
  height: 100%;
  overflow: hidden;

  ${mq.belowHorizontal} {
    padding-bottom: ${props => props.last ? '15rem' : '2rem'};
    padding-top: 4rem;
    ${horizontalPadding()}
  }

  ${mq.horizontal} {
    display: flex;
    padding-bottom: ${horizontalScale(122)};
    padding-top: ${horizontalScale(186)};
    width: 100%;

    ${ArticleText} {
      max-width: ${horizontalScale(460)};
    }
  }
`

export const ArticleNext = styled.div`
  position: relative;
  padding: 8.4rem 3.2rem 10rem 5.8rem;
  overflow: hidden;
  color: ${colors.light};
  background-color: ${colors.dark};

  ${mq.horizontal} {
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    padding-left: ${horizontalScale(138)};
    padding-right: ${horizontalScale(320)};
    padding-top: ${horizontalScale(182)};
  }

  ${PageTransitionLinkMain} {
    opacity: 0;

    ${mq.belowHorizontal} {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  ${SplitTextWrapper} {
    ${mq.belowHorizontal} {
      transform: translateY(0.9rem);
    }
  }
`

const ArticleNextLabelStyles = css`
  position: absolute;
  font-size: 1.2rem;
  text-transform: uppercase;
`

export const ArticleNextLabelDown = styled.p`
  ${ArticleNextLabelStyles}
  top: 7.3rem;
  left: 4rem;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateZ(0);

  ${mq.horizontal} {
    top: ${horizontalScale(165)};
    left: ${horizontalScale(98)};
  }
`

export const ArticleNextLabelRight = styled.p`
  ${ArticleNextLabelStyles}
  top: 5.2rem;
  left: 2.6rem;

  ${mq.horizontal} {
    top: ${horizontalScale(136)};
    left: ${horizontalScale(86)};
  }
`

export const ArticleNextImage = styled.div``

export const ArticleNextName = styled.div`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 3.8rem;
  line-height: 1;
  text-transform: uppercase;
  white-space: normal;

  ${mq.horizontal} {
    top: ${horizontalScale(248)};
    left: ${horizontalScale(210)};
    font-size: ${horizontalScale(38)};
    max-width: 75%;
  }
`

export const ArticleNextDate = styled.p`
  ${ArticleNextLabelStyles}
  padding-bottom: .2rem;
  position: relative;
`

import { graphql } from 'gatsby'
import React from 'react'
import Article from '../components/Article'
import SEO from '../components/SEO'

const PressTemplate = ({ data, pageContext }) => {
  const {
    seoDescription,
    name,
    featureImage,
    dateOfPublish,
    soundcloudId,
    vimeoId,
    articleIntro,
    introImage,
    articleContent,
    art
  } = data.contentfulPress

  return (
    <>
      <SEO title={name} description={seoDescription?.seoDescription} />
      <Article
        title={name}
        name={name}
        featureImage={featureImage}
        label={dateOfPublish}
        soundcloud={soundcloudId}
        vimeo={vimeoId}
        intro={articleIntro}
        introImage={introImage}
        article={articleContent}
        art={art}
        next={pageContext.next}
      />
    </>
  )
}

export default PressTemplate

export const pageQuery = graphql`
  query PressBySlug($slug: String!) {
    contentfulPress(slug: { eq: $slug }) {
      seoDescription {
        seoDescription
      }
      name
      featureImage {
        gatsbyImageData(quality: 80, width: 2000)
        description
      }
      dateOfPublish
      soundcloudId
      vimeoId
      articleIntro {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            description
            gatsbyImageData
          }
        }
      }
      introImage {
        gatsbyImageData(quality: 80, width: 2000)
        description
      }
      articleContent {
        ...on ContentfulRichTextBlock {
          text {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                title
                description
                gatsbyImageData
              }
            }
          }
        }
      }
      art {
        type: __typename
        ... on Node {
          ... on ContentfulImage {
            title
            description
            file {
              file {
                details {
                  image {
                    width
                  }
                }
              }
              gatsbyImageData(quality: 80)
            }
          }
          ... on ContentfulVideo {
            title
            description
            vimeoId
          }
        }
      }
    }
  }
`
